var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('MobileAppBanner'),(_vm.showLocaleNotify())?_c('div',{staticClass:"lang-notify"},[_c('span',{domProps:{"innerHTML":_vm._s(
				_vm.$t('SWITCH_LANG_NOTIFY', {
					browserLang: _vm.getLanguageParameter(
						_vm.browserLocale ? _vm.browserLocale.split('-')[0] : '',
						'name'
					),
				})
			)}}),_c('a',{staticClass:"switch-button",on:{"click":function($event){return _vm.switchLanguage()}}},[_vm._v(_vm._s(_vm.$t("SWITCH_NOW", { browserLangFlag: _vm.getLanguageParameter( _vm.browserLocale ? _vm.browserLocale.split("-")[0] : "", "flag" ), browserLang: _vm.getLanguageParameter( _vm.browserLocale ? _vm.browserLocale.split("-")[0] : "", "name" ), })))])]):_vm._e(),_c('router-view'),_c('CookieBanner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }