import Vue from 'vue';
import Router from 'vue-router';
import {i18n} from "./plugins/i18n.js";

const routes = require("./routes");

Vue.use(Router);

const localizationRoutes = routes.find((route) => route.name === 'Localization');

localizationRoutes.children.forEach(route => {
	route.component = () => import("./pages/" + route.name);
	if (route.children && route.children.length > 0) {
		route.children.forEach(child => {
			child.component = () => import("./pages/" +  child.name)
		});
	}
	if (route.name === 'Main') {
		route.beforeEnter = (to, from, next) => {
			// const lang = to.params.lang;
			// if (lang !== 'en') return next('en');
			if (i18n.locale !== to.params.lang) i18n.locale = to.params.lang;
			next();
		}
	}
});

// console.log(routes);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return (savedPosition) ? savedPosition : { x: 0, y: 0 };
	}
})
