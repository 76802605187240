// valid page routes for custom ad pages
const page_list = [
    ['/stocks-home', 'Diversify Your Portfolio with 600+ Stocks'],
    ['/stocks-home1', 'Diversify Your Portfolio with 600+ Stocks'],
    ['/ru/stocks-home-ru', 'Диверсифицируйте свой портфель 600+ акциями'],
    ['/us-stocks', 'Gain access to the U.S. stock market with ZERO fees'],
    ['/ru/us-stocks-ru', 'Получите доступ к фондовому рынку США с нулевой комиссией'],
    ['/ru/us-stocks-ru-short', 'Получите доступ к фондовому рынку США с нулевой комиссией'],
    ['/crypto', 'Zero fees on trading crypto and over 700 other assets'],
    ['/crypto-short', 'Zero fees on trading crypto and over 700 other assets'],
    ['/ru/crypto-ru', 'Никаких комиссий за торговлю криптовалютой и 700+ другими активами'],
    ['/ru/crypto-ru-short', 'Никаких комиссий за торговлю криптовалютой и 700+ другими активами'],
    ['/trading', '800+ markets to discover your trading edge'],
    ['/trading-short', '800+ markets to discover your trading edge'],
    ['/ru/trading-ru', '800+ рынков для торговли и инвестиций'],
    ['/ru/trading-ru-short', '800+ рынков для торговли и инвестиций'],
    ['/learn-to-trade', 'The easiest way to get into trading and investing'],
    ['/ru/learn-to-trade-ru', 'Самый легкий путь к торговле и инвестициям'],
    ['/ru/broker-less-trading-ru', 'Торгуйте на любых рынках без посредников'],
    ['/forex', 'Trade Forex and any other markets 24/7 with zero fees'],
    ['/ru/forex-ru', 'Торгуйте на Форексе и любом другом рынке 24/7 без комиссий'],
    ['/short-selling', 'Trade 800+ markets in any direction'],
    ['/short-selling-short', 'Trade 800+ markets in any direction'],
    ['/ru/short-selling-ru', 'Торгуйте на 800+ рынках в любом направлении'],
    ['/fractional-trading', 'Start investing with just $1'],
    ['/ru/fractional-trading-ru', 'Начните инвестировать всего с $1'],
    ['/leverage', 'Maximize your profits with up to 10x leverage'],
    ['/ru/leverage-ru', 'Увеличьте свою прибыль с кредитным плечом до 1:10']
];



module.exports = page_list;