export default {
  methods: {
    /** Function which checks if provided object is empty  */
    isEmpty(obj) {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
    /** Converts HTML entities to proper string character, used for wordpress data */
    decodeHtmlEntity(str) {
      return str.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
      });
    },
    /** Remove HTML tags around strings and just return raw string */
    cleanText(string) {
      if (!string) return '';
      let cleanedText = '';
      // Remove HTML tags
      cleanedText = string.replace(/<\/?[^>]+(>|$)/g, "");
      cleanedText = cleanedText.replace(/&amp;/g, '&');
      // Re-encode HTML entities
      cleanedText = this.decodeHtmlEntity(cleanedText);
      return cleanedText;
    },
    /** Function which truncates string after provided length and adds ... */
    truncate(string, position) {
      if (string && string.length > position)
        return string.substring(0, position) + '...';
      else return string;
    },
    formatUSDEstimate(tokens, mphPrice, round) {
      let usdValue = tokens * mphPrice
      usdValue = Math.round(usdValue / round) * round
      return usdValue.toLocaleString();
    },
    numberSuffixFormatter(number) {
      if (number > 999 && number <= 999999)
        return (number / 1000).toFixed(1) + 'K';
      else if (number > 999999) return (number / 1000000).toFixed(1) + 'M';
      return number;
    },

    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
      {
         return 'ios';
      }
      else if( userAgent.match( /Android/i ) )
      {
         return 'android';
      }
      else
      {
         return 'unknown';
      }
   },
    firstLetterUppercase(string, lowercaseFirst = false) {
      if (lowercaseFirst) string = string.toLowerCase();

      const split = string.split('_');
      if(split.length === 2){
        string = split[0].charAt(0).toUpperCase() + split[0].slice(1) + ' ' + split[1].charAt(0).toUpperCase() + split[1].slice(1);
      }
      else string = string.charAt(0).toUpperCase() + string.slice(1);

      return string;
    },
    orderParser(orderJSON) {
      const formatedMessage =
        'MarketID: ' +
        orderJSON.market.id +
        '\nQuantity: ' +
        orderJSON.amount +
        '\nType: ' +
        orderJSON.type +
        '\nDirection: ' +
        orderJSON.direction +
        '\nTimestamp: ' +
        orderJSON.timestamp +
        '\nOrderID: ' +
        orderJSON.id +
        '\nAddress: ' +
        orderJSON.eth_address;

      return formatedMessage;
    },
    transactionParser(transaction) {
      return (
        'TransactionID: ' +
        transaction.id +
        '\nQuantity: ' +
        transaction.amount +
        '\nCurrency: ' +
        'MPX' +
        '\nTimestamp: ' +
        transaction.timestamp +
        '\nType: ' +
        transaction.type +
        '\nAddress: ' +
        transaction.eth_address
      );
    },
    formatEthAddress(eth_address) {
      return eth_address
        ? eth_address.substr(0, 5) +
            '...' +
            eth_address.substr(eth_address.length - 3)
        : '';
    },
    /** Function which generates market id from type + symbol  */
    formatMarketId(type, symbol) {
      return (type + '_' + symbol).toUpperCase().replace(/\./g, '_');
    },
    /** Function which generates labels for specific market type  */
    formatMarketType(type) {
      switch (type) {
        case 'stock':
          return 'Stocks';
        case 'forex':
          return 'Foreign Exchange';
        case 'crypto':
          return 'Cryptocurrencies';
        case 'commodity':
          return 'Commodities';
        case 'exotic':
          return 'Exotic Assets';
      }
      return null;
    },
    isNumber(evt, input) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      // Numbers and dot only
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        // If "."(dot) already exists - prevent
        // Only 2 decimals after the comma
        if (
          (charCode === 46 && input.indexOf('.') !== -1) ||
          (input.indexOf('.') !== -1 && input.split('.')[1].length >= 2)
        )
          evt.preventDefault();
        else return true;
      }
    },
    daysRemaining(date) {
      const eventDate = this.$moment(date);
      const todayDate = this.$moment();
      return Math.abs(eventDate.diff(todayDate, 'days'));
    },
    roundFormatter(param) {
      const price = parseFloat(param);
      const abs = Math.abs(price);
      let round = 0;
      if (10000 > abs && abs >= 10) round = 2;
      else if (10 > abs && abs >= 1) round = 3;
      else if (1 > abs && abs > 0.1) round = 4;
      else if (0.1 > abs && abs > 0.01) round = 5;
      else if (0.01 > abs) round = 6;
      return price ? price.toFixed(round) : 0;
    },
    floorRound(number, decimals) {
      // Take number to power of decimal places and grab int floor, then divide back to get decimals
      // toFixed() makes sure that always show decimals e.g. 100.70 shows up as 100.70 instead of 100.7
      return number
        ? (
            Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
          ).toFixed(decimals)
        : 0;
    },
    // Return deposit or withdrawal string depending on type parameter
	  depositOrWithdrawal(type) {
		  return type === 'withdraw' ? type + 'al' : type;
	  },
    // Return buy or sell string depending on direction parameter
    buyOrSell(direction) {
      return direction === 'long' ? 'buy' : 'sell';
    },
    // Returning the timestamp x days ago from today
    timestampBeforeXDays(x) {
      return new Date(new Date().setDate(new Date().getDate() - x)).getTime();
    },
    /**
     * Sign metamask function
     * @param publicAddress
     * @param stringToSign
     * @returns {Promise<any>}
     */
    async signMetamask(publicAddress, stringToSign) {
      return new Promise((resolve, reject) => {
        try {
          window.web3.eth.personal.sign(
            window.web3.utils.fromUtf8(stringToSign),
            publicAddress,
            (err, signature) => {
              if (err) return reject(err);
              return resolve({ publicAddress, signature });
            }
          );
        } catch (e) {
          return reject(e);
        }
      });
    },
	  /**
	   * Normal array.forEach method from js is not synchronous
	   * This function make "callback" run for each element in array synchronously
	   * @param array
	   * @param callback
	   */
	  async asyncForEach(array, callback) {
		  for (let index = 0; index < array.length; index++) {
			  await callback(array[index], index, array);
		  }
	  }
  }
};
