import axios from "axios";

const client = axios.create();

let loading = false;
const sleep = ms => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};

export default {
	getPriceIEX(type, symbol) {
		return client.get('https://api.iextrading.com/1.0/' + type.toLowerCase() + '/' + symbol.toLowerCase() + '/price');
	},
	getPrice(type, symbol) {
		console.log('getPrice');
		return client.get(process.env.VUE_APP_API + '/web/price/' + type.toLowerCase() + '/' + symbol.toLowerCase() );
	},
	getData(type, symbol) {
		console.log('getData');
		return client.get(process.env.VUE_APP_API + '/web/marketMinutely/' + type.toLowerCase() + '/' + symbol.toLowerCase());
	},
	getMarketMovers(type) {
		console.log('getMarketMovers');
		return client.get('https://api.iextrading.com/1.0/stock/market/collection/list?collectionName=' + type.toLowerCase());
	},
	getOwnMarkets() {
		console.log('getOwnMarkets');
		return client.get(process.env.VUE_APP_API + '/web/markets/');
	},
	getMPHPrice() {
		return client.get(process.env.VUE_APP_API + '/web/price/MORPHER/MPH_USD');
	},
	async checkLocation() {
		try {
			if (loading) {
				let counter = 0;
				while (loading && counter < 50) {
					await sleep(100)
					counter +=1;
				}
			}
			loading = true;
			let ip_location = JSON.parse(localStorage.getItem('ip_location') || '{}');

			if (!ip_location || !ip_location.timestamp || ip_location.timestamp < Date.now() - 1000 * 60 * 60 * 24) {
				const result= await client.get(process.env.VUE_APP_API + '/web/checkLocation/')
				let country_code = result.data;
	
				
				if (country_code && country_code.ip_address && country_code.country_code) {
					ip_location = { timestamp: Date.now(), country_code: country_code.country_code, ip_address: country_code.ip_address }
				} else {
					ip_location = { timestamp: Date.now(), country_code: 'unknown', ip_address: 'unknown' }
				}
				
				
				localStorage.setItem('ip_location', JSON.stringify(ip_location))
			}

			loading = false;
			return ip_location;
		} catch (err) {
			loading = false;
			return {ip_address: 'unknown', country_code: 'unknown'}
		}
	},

	async emailClick(mclid) {
		try {
			if (loading) {
				let counter = 0;
				while (loading && counter < 50) {
					await sleep(100)
					counter +=1;
				}
			}
			loading = true;
			let email_click = JSON.parse(localStorage.getItem('email_click') || '{}');

			if (!email_click || !email_click.mclid || email_click.mclid !== mclid) {
				const result= await client.post(process.env.VUE_APP_API + '/web/emailClick/', { mclid })
				const data  = { timestamp: Date.now(), mclid }
				localStorage.setItem('email_click', JSON.stringify(data))
			}

			loading = false;
			return email_click;
		} catch (err) {
			loading = false;
			return {mclid: 'unknown'}
		}
	},
}

