const isUserLoggedInTradingApp = () => {
    const accessToken = localStorage.getItem('morpher-access-token');

    if (accessToken) return true;

    return false;
}
const userWalletType = () => {
    const walletType = localStorage.getItem('walletType');
    if (walletType) return walletType;
    return '';
}
export {
    isUserLoggedInTradingApp,
    userWalletType
}