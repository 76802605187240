import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'
import VueHead from 'vue-head'
import VueProgressBar from 'vue-progressbar'
import Cookie from "js-cookie";
import VueGtag from "vue-gtag";
import {blocklist} from './utils/blocklist'

const supportedLocales = JSON.parse(process.env.VUE_APP_I18N_SUPPORTED_LOCALE) || ['en'];
const defaultLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE || 'en';

const page_list = require("./custom/page_list");
const englishOnlyRoutes = [
  'BlogPost',
  'CookiePolicy',
  'PrivacyPolicy',
  'Disclaimer',
  'TermsOfUse',
  'ReferralTerms',
  'Impressum',
  'Roadmap',
  'SecurityBounty',
  'AirdropFAQ',
  'Changelog',
  'MobileRedirect',
]

router.beforeEach((to, from, next) => {
  const custom_page = page_list.find(page=> page[0] == to.path)
  if (custom_page) {
    return next();
  }
  const hasLang = to.params && to.params.locale;
  const hadLang = from.params && from.params.locale;

  let locale = to.params.locale || Cookie.get('locale') || defaultLocale;

  var referrer = document.referrer;
  if (to.name === 'Airdrop' && referrer) {
    
    blocklist.forEach ( block => {
      if (referrer.toLowerCase().includes(block.toLowerCase())) {
        window.location.href = 'https://www.google.com/';
      }
    } )
    
  }

  
  if (to.name === 'Airdrop' && to.params.custom_invite) {
    next({
      params: {
        locale: undefined
      }
    });

    return;
  }

  if (!supportedLocales.includes(locale)) {
    locale = defaultLocale;
  }

  i18n.locale = locale;
  document.querySelector("html")?.setAttribute("lang", locale);
    if (locale === "ar")
        document.querySelector("html")?.setAttribute("dir", "rtl");
    else document.querySelector("html")?.removeAttribute("dir");
  Cookie.set('locale', locale);

  if (englishOnlyRoutes.includes(to.name)) {
    if(hasLang) {
      if (to.params.locale !== 'en') {
        let path = to.fullPath;
        
        path = path.replace('/' + to.params.locale, '');

        i18n.locale = to.params.locale;
        Cookie.set('locale', to.params.locale);
  
        next({
          path: `${path}`,
          params: {
            locale: to.params.locale,
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }

    return;
  }

  if (hasLang && hadLang && from.params.locale.toLowerCase() === to.params.locale.toLowerCase()) {
    next();
  }

  if (locale === 'en' && hasLang) {
    let path = to.fullPath;
    path = path.replace('/en', '');

    next({
      path: `${path}`,
      params: {
        locale: 'en'
      }
    });
  }

  if (locale !== 'en' && !hasLang) {
    next({
      path: `/${locale}${to.fullPath}`
    });
  }

  next();
});

Vue.use(VueAnalytics, {
  id: ['UA-127457140-1', 'UA-127457140-4', 'G-207331323', 'G-ZKPQS232M6'],
  linkers: ['morpher.com'],
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  },
  set: [{
      field: 'anonymizeIp',
      value: true
    },
    {
      field: 'allowAdFeatures',
      value: false
    }
  ],
  router
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_API_KEY,
    },
    params: {
      anonymize_ip: true,
      allow_google_signals: false,
    },      		  
    set: [
      {
        field: "anonymize_ip",
        value: true,
      },
      {
        field: "allow_google_signals",
        value: false,
      },
    ],
  },
  router
  );

Vue.config.productionTip = false;

// Highcharts library
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import 'highcharts/css/highcharts.css';
Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: ''
  }
});
Vue.use(HighchartsVue);
stockInit(Highcharts);

// Moment.js
import moment from 'vue-moment';
Vue.use(moment);

// Buefy - Bulma Css Library for Vue.js
import Buefy from 'buefy';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
Vue.use(Buefy, {
  defaultIconPack: 'fas'
});


// Global Mixin
import GlobalMixin from './mixins/global.mixin';
Vue.mixin(GlobalMixin);

// Multi-language i18n plugin
import {
  i18n
} from "./plugins/i18n.js";

// Load Vue Head
Vue.use(VueHead, {
  separator: '-',
  complement: 'Morpher'
})

// Top Progress Bar
Vue.use(VueProgressBar, {
  color: '#00c386',
  failedColor: '#fc6404',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
});

if (window.ym ) {

	window.ym(process.env.VUE_APP_YANDEX_API_KEY, "init", {
		clickmap:true,
		trackLinks:true,
		accurateTrackBounce:true
	});
}

// Click outside directive
Vue.directive('click-outside', {
  bind: (el, binding, vnode) => {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

const activeLanguagesList = [
  {
    code: "en",
    name: "English",
    flag: '🇬🇧'
  },
  // {
  // 	code: 'de',
  // 	name: 'Deutsch',
  //  flag: '🇩🇪'
  // },
  {
    code: 'ru',
    name: 'Русский',
    flag: '🇷🇺'
  },
  // {
  // 	code: 'pt',
  // 	name: 'Português',
  //  flag: '🇵🇹'
  // },
  // {
  // 	code: 'ar',
  // 	name: '‏العربية‏',
  //  flag: '🇸🇦'
  // },
  // {
  // 	code: 'zh',
  // 	name: '中文',
  //  flag: '🇨🇳'
  // },
  // {
  // 	code: 'ja',
  // 	name: '日本語',
  //  flag: '🇯🇵'
  // },
  // {
  // 	code: 'bs',
  // 	name: 'Bosanski',
  //  flag: '🇧🇦'
  // }
];

const getLanguageParameter = (languageCode, parameter) => {
  let value = activeLanguagesList[0][parameter];

  const findLang = activeLanguagesList.find((lang) => lang.code === languageCode);

  if (findLang) {
    value = findLang[parameter];
  }

  return value;
}

new Vue({
  router,
  render: h => h(App),
  i18n,
  // mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')

export {
  activeLanguagesList,
  getLanguageParameter
}