<template>
  <b-notification :active="!cookieConsent" class="cookie-banner" type="is-info" @close="cookiesAccepted()">
    <span v-html="$t('WE_USE_COOKIES')" />
    <br>
    <button class="button is-white" style="marginTop: 5px; width: 100%" @click="cookiesAccepted()">
      {{ $t('GOT_IT') }}
    </button>
  </b-notification>
</template>

<script>
import * as Cookies from 'js-cookie';
import utils from "@/services/Utils";

export default {
    name: "CookieBanner",
    data() {
        return {
            cookieConsent: false,
        }
    },
    created() {
        // Check if user already accepted cookies when component first loaded
        if (Cookies.get('cookieConsent') === 'true') {
            this.cookiesAccepted();
        }
    },
    methods: {
        cookiesAccepted () {
            utils.cookiesAccepted(this);
            this.cookieConsent = true;
        },
    },
};
</script>

<style scoped>
.cookie-banner {
    max-width: 250px;
    position: fixed; 
    bottom: 20px; 
    left: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
}
@media only screen and (max-width: 500px) {
    .cookie-banner {
        bottom: 0;
        max-width: 100%;
        left: auto;
    }
}
</style>
