import { i18n } from '../plugins/i18n';
import * as Cookies from 'js-cookie';
import emailService from "@/services/Email";

export default {

    /* 
    * Function uses to copy the given text to the clipboard. ses a hidden textArea to copy the text
    */
    copyToClipboard(text, self) {
        const textArea = document.createElement("textarea");
        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                self.$buefy.snackbar.open({position: 'is-bottom-left', message: i18n.t('copy_clipboard_success')});
            } else {
                self.$buefy.snackbar.open({position: 'is-bottom-left', message: i18n.t('copy_clipboard_fail')});
            }
        } catch (err) {
            self.$buefy.snackbar.open({position: 'is-bottom-left', message: i18n.t('copy_clipboard_fail')});
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    },
    /*
    * Open the specified social media network and pass a link for sharing
    * Link structure sourced from https://github.com/bradvin/social-share-urls
    */
    openSocialMediaWindow(network, shareLink, title) {
        let url = '';
        // Get the url for the social media network
        switch (network) {
            case 'twitter':
                url = 'https://twitter.com/share?url=' + shareLink + '&text=' + title + '&via=morpher_io';
                break;
                
            case 'twitter_ad':
                url = 'https://twitter.com/share?url=' + shareLink + '&text=' + title;
                break;

            case 'facebook':
                url = 'https://www.facebook.com/sharer/sharer.php?u=' + shareLink;
                break;
                
            case 'linkedin':
                url = 'http://www.linkedin.com/shareArticle?mini=true&url=' + shareLink + '&title=' + title + "&source=www.morpher.com";
                break;
                
            case 'reddit':
                url = 'https://reddit.com/submit?url=' + shareLink + '&title=' + title;
                break;
                
            case 'pocket':
                url = 'https://getpocket.com/edit?url=' + shareLink;
                break;
                
            case 'telegram':
                url = 'https://telegram.me/share/url?url=' + shareLink + '&text=' + title;
                break;
                
            case 'whatsapp':
                url = 'https://wa.me/?text=' + title + ' ' + shareLink;
                break;
                
            default:
                break;
        }
        
        // Open the social media network in a new window
        const posTop = screen.height/2 - 250;
        const posLeft = screen.width/2 - 250;
        const windowConfig = 'width=500px,height=500px,top=' + posTop + ',left=' + posLeft;
        window.open(url, '_blank', windowConfig);
    },
    /*
    * Enable cookies for advertiser tracking
    */
    cookiesAccepted (self) {
        // Inject Quora pixel
        !function(q,e,v,n,t,s) {
            if(q.qp) return; 
            n=q.qp=function() {
                n.qp ? n.qp.apply(n,arguments) : n.queue.push(arguments);
            };
            n.queue=[];
            t=document.createElement(e);
            t.async=!0;t.src=v; 
            s=document.getElementsByTagName(e)[0]; 
            s.parentNode.insertBefore(t,s);
        } (window, 'script', 'https://a.quora.com/qevents.js');
        qp('init', '1d2046b63b4648f5910da20eb3a2806a');
        qp('track', 'ViewContent');
        
        // Activate Google Advertising Features
        try {
            if (this.$gtag) {
                this.$gtag.set('anonymize_ip', false);
            }

        } catch {}

        try {
            if (this.$gtag) {
                this.$gtag.set('allow_google_signals', true);
            }
        } catch {}

        // load reddit pixel
        try {

            // load the reddit pixel SDK
            if (!document.getElementById('script_reddit_pixel')) {
                const pixelScript = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_en0sdd3j80ju');rdt('track', 'PageVisit');`

                const scriptElement = document.createElement('script');
                scriptElement.setAttribute('id', 'script_reddit_pixel');
                scriptElement.type = 'text/javascript';
                scriptElement.text = pixelScript;

                document.head.appendChild(scriptElement);
            }            
        } catch {}
        
        // Set in cookies that user accepted, unless already exists            
        if (Cookies.get('cookieConsent') !== 'true') {
            Cookies.set('cookieConsent', 'true', { expires: 30 });
        }
    },
    async getAirdropRewards(textToReplace) {
        try {
            const airdropAmount = await emailService.getAirdropAmount();
            if (airdropAmount) {
                const amount = airdropAmount.airdrop_amount > 0 ? Math.floor(airdropAmount.airdrop_amount) : 2000;
                const reward = Math.floor(amount * .03).toLocaleString();
                const tokens = Math.floor(amount).toLocaleString();
                return textToReplace.replace('60', reward).replace('2,000', tokens);
            } else return textToReplace;
        }
        catch (err) {
            return textToReplace;
        }
    },
};